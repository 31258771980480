<template>
  <v-main>
    <v-container class="main-container h-100" fluid>
      <h2 class="ml-4">{{ $_strings.orderHome.DESC_ORDER }}</h2>
      <v-row class="mb-4" justify="space-around">
        <v-col cols="auto">
          <summary-item
            status="new"
            :loading="isLoadingShipmentSummary"
            :count="shipmentSummary.created"
            href="/main/status-pesanan/list/process"
          />
        </v-col>
        <v-col cols="auto">
          <summary-item
            status="pickup"
            :loading="isLoadingShipmentSummary"
            :count="shipmentSummary.pickup"
            href="/main/status-pesanan/list/process"
          />
        </v-col>
        <v-col cols="auto">
          <summary-item
            status="delivery"
            :loading="isLoadingShipmentSummary"
            :count="shipmentSummary.delivery"
            href="/main/status-pesanan/list/process"
          />
        </v-col>
        <v-col cols="auto">
          <summary-item
            status="confirmation"
            :count="shipmentSummary.confirm"
            :loading="isLoadingShipmentSummary"
            :href="`/main/status-pesanan/list/needconfirmation?fromDate=${dayjs().subtract('60', 'days').format('YYYY-MM-DD')}`"

          />
        </v-col>
        <v-col cols="auto">
          <summary-item
            status="complete"
            :loading="isLoadingShipmentSummary"
            :count="shipmentSummary.completed && (shipmentSummary.completed > 999 ? '999+' : shipmentSummary.completed)"
            href="/main/status-pesanan/list/completed"
          />
        </v-col>
      </v-row>
      <v-row v-if="userAccess.canCreate" class="mt-12" justify="center">
        <v-col cols="12" sm="8" md="5">
          <v-sheet
            class="mb-10 summary-item mt-5 text-left d-flex text-decoration-none"
            rounded
            elevation="3"
            tag="a"
            min-width="250"
          >
            <v-container class="mb-2 pa-4 d-inline-flex">
              <v-row
                justify="center"
                align="center"
              >
                <v-col class="text-center" cols="10">
                  {{ $_strings.orderHome.GREET }} {{ user.name }}
                </v-col>
                <v-col class="text-center" cols="10">
                  {{ $_strings.orderHome.QUESTION }}
                </v-col>
                <v-col class="text-center" cols="10">
                  <v-btn @click="$router.push('/main/buat-pesanan/create')" large color="primary" class="white--text font-weight-bold body-2 w-100" type="submit">
                    {{ $_strings.orderHome.CREATE_NEW_ORDER }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';
import SummaryItem from './SummaryItem';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  name: 'OrderPage',
  components: {
    SummaryItem,
  },
  data() {
    return {
      isLoadingShipmentSummary: false,
      shipmentSummary: {
        completed: '',
        confirm: '',
        created: '',
        delivery: '',
        pickup: '',
      },
      user: {
        name: '',
        role: '',
      },
    };
  },
  created() {
    source = CancelToken.source();
  },
  beforeDestroy() {
    source.cancel('CANCELED_BY_THE_USER');
  },
  mounted() {
    const dataUser = this.$store.state.user.myUserInfo;
    this.user.name = dataUser.username;
    this.getShipmentSummary();
  },
  methods: {
    dayjs,
    getShipmentSummary() {
      this.isLoadingShipmentSummary = true;
      this.$_services.order.getShipmentSummary(source)
        .then((res) => {
          this.shipmentSummary = res?.data;
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          this.isLoadingShipmentSummary = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-main {
  background-image: url('~@/assets/images/bg-login.png');
  background-size: 920px 337px;
  background-position: bottom;
  background-color: $--color-bg-alabaster;
  padding: 0 !important;
  min-height: 95%;
  .container {
    background: transparent;
  }
}
</style>
