<template>
  <v-sheet
    class="summary-item mt-5 text-left d-flex text-decoration-none"
    width="190"
    height="85"
    rounded
    elevation="3"
    tag="div"
    @click="$router.push(href)"
  >
    <v-container class="pa-0 d-inline-flex">
      <v-row
        no-gutters
        align="center"
      >
        <v-col cols="auto">
          <v-sheet class="summary-icon pa-2" rounded>
            <v-img
              :class="status"
              contain
              :src="summaryContent.img"
              width="70"
            ></v-img>
            <div class="font-weight-bold text-caption white--text">
              {{summaryContent.title}}
            </div>
          </v-sheet>
        </v-col>
        <v-col
          class="text-center"
          :class="!!summaryContent.caption ? 'with-caption': ''"
        >
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <div v-else>
            <span class="font-weight-bold text-h4 summary-count">
              {{count}}
            </span>
            <br>
            <span
              v-if="summaryContent.caption"
              class="font-weight-bold text-body-2 grey--text"
            >
              {{summaryContent.caption}}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: 'new',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    count: {
      type: [String, Number],
      default: 0,
    },
  },
  computed: {
    summaryContent() {
      switch (this.status) {
        case 'new':
          return {
            title: 'Order Baru',
            img: require('../../assets/images/order-new.png'),
            caption: '',
          };
        case 'confirmation':
          return {
            title: 'Konfirmasi',
            img: require('../../assets/images/order-problem.png'),
            caption: '',
          };
        case 'pickup':
          return {
            title: 'Jemput',
            img: require('../../assets/images/order-pickup.png'),
            caption: '',
          };
        case 'delivery':
          return {
            title: 'Kirim',
            img: require('../../assets/images/order-delivery.png'),
            caption: '7 Hari Terakhir',
          };
        case 'complete':
          return {
            title: 'Selesai',
            img: require('../../assets/images/order-complete.png'),
            caption: '7 Hari Terakhir',
          };
        default:
          return {
            title: 'Order Baru',
            img: require('../../assets/images/order-new.png'),
            caption: '7 Hari Terakhir',
          };
      }
    },
  },
};
</script>

<style lang="scss">
.summary-item {
  cursor: pointer;
  .summary-icon {
    width: 84px;
    height: 84px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    background-color: #02499a;
  }
  .with-caption {
    margin-bottom: -15px;
  }
}
</style>
